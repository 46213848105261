@font-face {
    font-family: 'Eina02';
    src: url('./../fonts/Eina02/Eina02-Regular.woff2') format('woff2'),
         url('./../fonts/Eina02/Eina02-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
  font-family: 'Eina02';
  src: url('./../fonts/Eina02/Eina02-RegularItalic.woff2') format('woff2'),
       url('./../fonts/Eina02/Eina02-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Eina02';
  src: url('./../fonts/Eina02/Eina02-Bold.woff2') format('woff2'),
       url('./../fonts/Eina02/Eina02-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Eina02';
  src: url('./../fonts/Eina02/Eina02-BoldItalic.woff2') format('woff2'),
       url('./../fonts/Eina02/Eina02-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Eina02';
  src: url('./../fonts/Eina02/Eina02-Light.woff2') format('woff2'),
       url('./../fonts/Eina02/Eina02-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Eina02';
  src: url('./../fonts/Eina02/Eina02-LightItalic.woff2') format('woff2'),
       url('./../fonts/Eina02/Eina02-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
